.inputGroup {
  width: 50%;
  padding-bottom: 10px; }
  .inputGroup input:not([type=radio]) {
    display: block;
    width: 95%;
    padding: 8px;
    margin-top: 5px;
    font-size: 13px;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    border: 0; }
  .inputGroup select:focus,
  .inputGroup input:focus {
    outline-color: var(--secondary-background); }
  .inputGroup label {
    display: block;
    font-size: 12px; }
  .inputGroup.w_25 {
    width: auto;
    max-width: 80px; }
  .inputGroup.w_33 {
    width: auto;
    max-width: 140px; }
  .inputGroup select {
    line-height: 30px;
    height: 32px;
    margin-top: 5px;
    margin-right: 10px; }
  .inputGroup.w_50 {
    width: 50%; }
    .inputGroup.w_50 input {
      display: inline-block;
      width: auto;
      margin-right: 10px; }
  .inputGroup .flex label {
    display: inline-block;
    line-height: 25px;
    margin-right: 10px;
    margin-top: 7px; }
  @media (max-width: 480.5px) {
    .inputGroup {
      width: 100%; } }

.error {
  color: #bb3030;
  font-size: 11px; }
