.counter {
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5%; }
  .counter .labelContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .counter .count {
    font-size: 30px;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px; }
  .counter .counters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .counter .button {
    border: 2px solid;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-left: 0.5px; }
