.home .content .list .centerColumn {
  width: 90%;
  margin: 0 auto; }

@media (max-width: 700px) {
  .home .hideMobile {
    display: none; }
  .home .content {
    width: 100% !important; } }

@media (min-width: 700px) {
  .showMobile {
    display: none; } }

.closeIcon {
  padding: 5px;
  -ms-flex-item-align: end;
      align-self: flex-end; }
  .closeIcon i:before {
    color: #000;
    font-size: 32px;
    font-weight: bold; }
