.calendarioWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .calendarioWrapper .labels {
    clear: both; }
    .calendarioWrapper .labels .label {
      width: 50%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      display: inline-block;
      margin-bottom: 5px;
      max-width: 350px; }
    .calendarioWrapper .labels .label:last-child {
      padding-left: 10px; }
  .calendarioWrapper .calendario {
    clear: both; }
  .calendarioWrapper .separatore {
    width: 20px; }
  .calendarioWrapper .nights {
    min-width: 35px; }
    .calendarioWrapper .nights span {
      display: block; }
    .calendarioWrapper .nights .label {
      margin-bottom: 6px; }
    .calendarioWrapper .nights .text {
      line-height: 40px;
      height: 40px;
      border-bottom: 1px solid;
      text-align: center; }

@media (max-width: 1080.5px) {
  .calendarioWrapper {
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 10px; } }

@media (max-width: 600.5px) {
  .calendarioWrapper {
    margin-bottom: 6px; }
    .calendarioWrapper .labels {
      font-size: 11px; } }

:global .DayPicker td,
:global .DayPicker th {
  border-color: #fff;
  border: none;
  outline: none !important; }

:global .DayPicker td:not(.CalendarDay__selected_span) .onlyCheckout {
  cursor: pointer;
  opacity: 0.85 !important; }
  :global .DayPicker td:not(.CalendarDay__selected_span) .onlyCheckout:before {
    content: none;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -120%);
        -ms-transform: translate(-50%, -120%);
            transform: translate(-50%, -120%);
    padding: 5px;
    background-color: var(--main-background-color);
    border: 1px solid var(--secondary-background);
    width: 35px;
    font-size: 12px;
    color: var(--text-color);
    min-width: 100px;
    opacity: 1; }
  :global .DayPicker td:not(.CalendarDay__selected_span) .onlyCheckout:hover:before {
    content: "Solo Check-Out"; }
  :global .DayPicker td:not(.CalendarDay__selected_span) .onlyCheckout:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid var(--secondary-background);
    -webkit-transform: translate(-50%, -50%) scale(0.8);
        -ms-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
    left: 50%;
    top: 50%;
    opacity: 0.65;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    max-width: 50px;
    max-height: 50px;
    width: 100%;
    height: 100%; }

:global tbody > tr:first-child td:hover {
  z-index: 2; }

:global tbody > tr:first-child td .onlyCheckout:before {
  -webkit-transform: translate(-50%, 150%) !important;
      -ms-transform: translate(-50%, 150%) !important;
          transform: translate(-50%, 150%) !important; }

:global tbody > tr > td:first-child .onlyCheckout:before {
  left: 50px !important; }

:global tbody > tr > td:last-child .onlyCheckout:before {
  left: -10px !important; }

:global .CalendarDay__default:not(.CalendarDay__blocked_calendar) {
  border-radius: 50%;
  color: var(--text-color);
  position: relative;
  border: none;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s; }
  :global .CalendarDay__default:not(.CalendarDay__blocked_calendar):after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid var(--secondary-background);
    -webkit-transform: translate(-50%, -50%) scale(0.8);
        -ms-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
    left: 50%;
    top: 50%;
    opacity: 1;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    max-width: 50px;
    max-height: 50px;
    width: 100%;
    height: 100%; }
  :global .CalendarDay__default:not(.CalendarDay__blocked_calendar).CalendarDay__selected_span:after, :global .CalendarDay__default:not(.CalendarDay__blocked_calendar).CalendarDay__hovered_span:after, :global .CalendarDay__default:not(.CalendarDay__blocked_calendar).CalendarDay__blocked_minimum_nights:after {
    opacity: 0; }

:global #top_bar_calendar .CalendarDay__blocked_calendar:not(.CalendarDay__hovered_span):not(.CalendarDay__selected_span):hover,
:global #top_bar_calendar .CalendarDay__blocked_calendar,
:global #top_bar_calendar .CalendarDay__blocked_calendar:active {
  opacity: 0.4; }

:global #top_bar_calendar .DateRangePickerInput .CalendarDay__default:after {
  content: none; }

:global .CalendarDay__default:active,
:global .CalendarDay__default:hover {
  background-color: var(--secondary-background);
  color: #fff; }
  :global .CalendarDay__default:active:after,
  :global .CalendarDay__default:hover:after {
    opacity: 0; }

:global .CalendarDay__blocked_calendar:not(.CalendarDay__hovered_span):not(.CalendarDay__selected_span):not(.CalendarDay__selected_start):not(.CalendarDay__selected_end):hover,
:global .CalendarDay__blocked_calendar,
:global .CalendarDay__blocked_calendar:active {
  border-radius: 50%;
  outline: none;
  color: var(--text-color) !important;
  background: transparent;
  border: none; }

:global .CalendarDay__blocked_calendar {
  position: relative; }
  :global .CalendarDay__blocked_calendar .noDispo:after {
    content: "";
    position: absolute;
    background-color: var(--text-color);
    height: 1px;
    width: 58%;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        -ms-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
    left: 50%;
    top: 50%;
    opacity: 0.5 !important; }

:global .CalendarDay__blocked_calendar:not(.CalendarDay__hovered_span):not(.CalendarDay__selected_span):hover > *,
:global .CalendarDay__blocked_calendar > *,
:global .CalendarDay__blocked_calendar:active > * {
  opacity: 0.8; }

:global .CalendarDay__blocked_calendar:hover {
  border: none; }

:global .CalendarDay__selected_span,
:global .CalendarDay__hovered_span,
:global .CalendarDay__blocked_minimum_nights {
  background: var(--secondary-background);
  border-radius: 0 !important;
  z-index: 0;
  position: relative; }
  :global .CalendarDay__selected_span:before,
  :global .CalendarDay__hovered_span:before,
  :global .CalendarDay__blocked_minimum_nights:before {
    content: "";
    position: absolute;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background-color: #fff;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.5;
    z-index: -1;
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s; }

:global .CalendarDay__selected_span:not(.CalendarDay__blocked_calendar):hover:before {
  opacity: 0.15; }

:global .CalendarDay__hovered_span:hover {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }
  :global .CalendarDay__hovered_span:hover:before {
    opacity: 0; }

:global .CalendarDay__default.CalendarDay__selected_start,
:global .CalendarDay__default.CalendarDay__selected_end {
  background: var(--secondary-background);
  color: #fff !important;
  z-index: 1; }
  :global .CalendarDay__default.CalendarDay__selected_start:before,
  :global .CalendarDay__default.CalendarDay__selected_end:before {
    opacity: 0; }

:global .CalendarDay__selected_start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

:global .CalendarDay__selected_end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

@media (max-width: 1023.5px) {
  :global #calendar .DayPicker {
    width: 99% !important;
    left: 0.5%; } }

:global .DateRangePickerInput {
  background-color: transparent; }

:global .DateRangePicker, :global .DateRangePickerInput {
  position: relative;
  display: inline-block;
  width: 100%; }

:global .DateInput {
  border-radius: 5px;
  height: 40px;
  width: 17vw;
  max-width: 300px; }
  :global .DateInput:after {
    font-family: 'booking-engine' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e960";
    width: 39px;
    border-left: 1px solid;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: var(--title-color);
    pointer-events: none; }
  @media (max-width: 1080.5px) {
    :global .DateInput {
      width: 47.28%;
      max-width: 340px; } }

:global .DateInput_input {
  background-color: var(--searchbar-background);
  color: var(--searchbar-color);
  border: 1px solid var(--searchbar-color);
  position: relative;
  border-radius: 5px;
  padding: 7px 40px 7px 10px;
  height: 40px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 300;
  cursor: pointer;
  outline: none; }

:global ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--searchbar-color); }

:global :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--searchbar-color); }

:global ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--searchbar-color); }

:global ::placeholder {
  /* Most modern browsers support this now. */
  color: var(--searchbar-color); }

:global .DateRangePicker_picker__directionLeft {
  left: 0;
  margin-bottom: 16px;
  margin-top: -4px;
  -webkit-box-shadow: 0px 2px 10px -3px #000;
          box-shadow: 0px 2px 10px -3px #000; }

:global .DateInput_fang {
  margin-bottom: 17px;
  margin-top: -3px; }

:global .secondary-background .DateInput_input {
  background-color: var(--secondary-background);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  position: relative;
  border-radius: 5px;
  padding: 7px 40px 7px 10px;
  height: 40px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 300;
  cursor: pointer; }

:global .secondary-background ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--secondary-color); }

:global .secondary-background :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--secondary-color); }

:global .secondary-background ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--secondary-color); }

:global .secondary-background ::placeholder {
  /* Most modern browsers support this now. */
  color: var(--secondary-color); }

:global .DayPicker_weekHeader, :global .CalendarMonth_caption {
  color: var(--text-color); }

:global .DayPicker_weekHeader_li {
  opacity: 0.6; }

:global body.touch .DayPickerNavigation_button__horizontalDefault {
  border-color: #e4e7e7 !important; }

:global #calendar .CalendarMonth {
  padding: 0 calc(5px + 0.3vw) !important; }

:global #calendar .CalendarMonthGrid_month__horizontal {
  width: 33%; }

:global #calendar .CalendarMonth_table {
  margin: 0 auto; }

:global #calendar .CalendarMonthGrid {
  width: 150% !important;
  left: 0; }

:global #calendar .DayPicker_weekHeaders__horizontal {
  width: 100%;
  margin: 0;
  position: relative;
  left: 0; }

:global #calendar .DayPicker_weekHeader {
  padding: 0 !important;
  width: 50%;
  text-align: center; }

:global #calendar .DayPicker_weekHeader + .DayPicker_weekHeader {
  left: 49.3% !important; }

:global #calendar .DayPicker td, :global #calendar .DayPicker th {
  width: 3vw !important;
  height: 3vw !important;
  max-width: 56px !important;
  max-height: 55px !important; }

:global #calendar .DayPicker_weekHeader_li {
  width: 3vw !important;
  min-width: 38px;
  max-width: 56px; }

:global #calendar .DayPicker {
  width: 99% !important; }

:global #calendar .DayPicker > div > div, :global #calendar .DayPicker_transitionContainer {
  width: 100% !important;
  max-height: 425px;
  min-height: 310px; }

@media (min-width: 1830.5px) {
  :global #calendar .DayPicker td, :global #calendar .DayPicker th {
    width: 55px !important;
    height: 55px !important; }
  :global #calendar .DayPicker_weekHeader_li {
    width: 55px !important; } }

@media (min-width: 1280.5px) {
  :global #calendar .DayPicker > div > div, :global #calendar .DayPicker_transitionContainer {
    height: 24vw !important; } }

@media (max-width: 650.5px) {
  :global #calendar .CalendarMonthGrid {
    width: 300% !important; }
  :global #calendar .CalendarMonthGrid_month__horizontal {
    width: 33.333%; }
  :global #calendar .DayPicker_weekHeader {
    width: 100%; }
  :global #calendar .DayPicker_weekHeader + .DayPicker_weekHeader {
    display: none; } }

@media (max-width: 600.5px) {
  :global .DateInput_input,
  :global .secondary-background .DateInput_input {
    font-size: calc(10px + 0.25vw);
    padding: 5px 33px 5px 7px;
    height: 33px; }
  :global .DateInput {
    height: 33px; }
    :global .DateInput:after {
      width: 33px;
      font-size: 17px;
      line-height: 33px; }
  :global .DateRangePickerInput_arrow {
    width: 5%; }
  :global .DateRangePicker_picker__directionLeft {
    margin-bottom: 0; }
  :global .DateInput_fang {
    margin-bottom: 1px; } }
