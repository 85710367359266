.form {
  padding: 6px 0 20px 0;
  color: var(--text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .form .pagaconcarta {
    padding: 30px 0;
    width: 100%; }
    .form .pagaconcarta h4 {
      margin-bottom: 10px; }
    .form .pagaconcarta .pagaconcartaInner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .form .inputGroup {
    width: 50%;
    padding-bottom: 10px; }
    .form .inputGroup input:not([type=radio]) {
      display: block;
      width: 95%;
      padding: 8px;
      margin-top: 5px;
      font-size: 13px;
      -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
              box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
      border-radius: 3px;
      border: 0; }
    .form .inputGroup select:focus,
    .form .inputGroup input:focus {
      outline-color: var(--secondary-background); }
    .form .inputGroup label {
      display: block;
      font-size: 12px; }
    .form .inputGroup.w_25 {
      width: auto;
      max-width: 80px; }
    .form .inputGroup.w_33 {
      width: auto;
      max-width: 140px; }
    .form .inputGroup select {
      line-height: 30px;
      height: 32px;
      margin-top: 5px;
      margin-right: 10px; }
    .form .inputGroup.w_50 {
      width: 50%; }
      .form .inputGroup.w_50 input {
        display: inline-block;
        width: auto;
        margin-right: 10px; }
    .form .inputGroup .flex label {
      display: inline-block;
      line-height: 25px;
      margin-right: 10px;
      margin-top: 7px; }
    @media (max-width: 480.5px) {
      .form .inputGroup {
        width: 100%; } }
  .form .inputGroupBlock {
    width: 100%;
    padding-bottom: 10px; }
    .form .inputGroupBlock label {
      font-size: 12px; }
    .form .inputGroupBlock textarea {
      display: block;
      width: 97.5%;
      max-width: 97.5%;
      padding: 8px;
      margin-top: 5px;
      font-size: 13px;
      -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
              box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
      border-radius: 3px;
      border: 0; }
      .form .inputGroupBlock textarea:focus {
        outline-color: var(--secondary-background); }
  .form .inputGroupPrivacy {
    margin-bottom: 13px;
    padding-left: 20px;
    position: relative; }
    .form .inputGroupPrivacy:first-child {
      margin-top: 8px; }
    .form .inputGroupPrivacy label {
      font-size: 12px; }
    .form .inputGroupPrivacy a {
      text-decoration: underline;
      font-size: 12px; }
    .form .inputGroupPrivacy input {
      margin-right: 5px;
      position: absolute;
      left: 0;
      top: 4px; }
  .form .error {
    color: #bb3030;
    font-size: 11px; }

.button {
  padding: 10px 30px;
  text-align: center;
  border-radius: 30px;
  color: var(--primary-color);
  border: none;
  vertical-align: bottom;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  font-size: 15px;
  margin-right: 10px; }
  .button:hover {
    -webkit-box-shadow: 0px 0px 25px -4px var(--title-color);
            box-shadow: 0px 0px 25px -4px var(--title-color); }
  .button:active, .button:focus {
    outline: none; }
  .button.auto {
    width: auto;
    font-size: calc(13px + 0.11vw);
    padding: 10px 30px; }
