.servizi_a_pagamento {
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: calc(15px + 2.5vw) 0;
  width: 100%;
  position: relative; }
  .servizi_a_pagamento:after, .servizi_a_pagamento:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--text-color);
    opacity: 0.25; }
  .servizi_a_pagamento:before {
    top: calc(7px + 1.2vw); }
  .servizi_a_pagamento:after {
    bottom: calc(7px + 1.2vw); }
  .servizi_a_pagamento .titolo {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 33%; }
  .servizi_a_pagamento .text {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 67%; }

.spacer {
  display: block;
  width: 100%;
  padding-top: 30px; }

@media (max-width: 1023.5px) {
  .servizi_a_pagamento {
    clear: both; }
    .servizi_a_pagamento .titolo {
      width: 100%;
      margin-bottom: 20px; }
    .servizi_a_pagamento .text {
      width: 100%; } }
