header {
  padding: 10px 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  z-index: 2001; }
  header .menu li {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px; }
    header .menu li a {
      padding: 10px;
      font-weight: 400;
      position: relative; }
    header .menu li a:hover,
    header .menu li a.active {
      font-weight: 600; }
      header .menu li a:hover:after,
      header .menu li a.active:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 2px;
        left: 0;
        background-color: var(--secondary-background); }
  header .logo img {
    height: 50px; }
  header .logo .nome {
    font-size: 30px;
    display: block;
    margin-bottom: 10px; }
  @media (min-width: 1023.5px) {
    header .menuButton {
      display: none !important; } }
  @media (max-width: 1023.5px) {
    header {
      height: 75px; }
      header nav {
        position: fixed;
        background: var(--primary-background);
        top: 75px;
        z-index: 999;
        right: -320px;
        height: calc(100vh - 75px);
        min-width: 290px;
        max-width: 320px;
        -webkit-transition: right 0.5s ease-in-out;
        -o-transition: right 0.5s ease-in-out;
        transition: right 0.5s ease-in-out; }
      header nav.open {
        right: 0; }
      header .menu {
        padding: 20px; }
        header .menu li {
          display: block;
          text-align: center;
          margin: 10px 0; }
          header .menu li a {
            display: inline-block; } }

.menuButton {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 10px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .menuButton .navicon {
    background: var(--primary-color);
    display: block;
    height: 2px;
    position: relative;
    -webkit-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
    width: 30px;
    border-radius: 6px; }
  .menuButton .navicon:before,
  .menuButton .navicon:after {
    background: var(--primary-color);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    width: 100%; }
  .menuButton .navicon:before {
    top: 8px; }
  .menuButton .navicon:after {
    top: -8px; }

.menuButton.activeMenu .navicon {
  background: transparent; }

.menuButton.activeMenu .navicon:before {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.menuButton.activeMenu .navicon:after {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg); }

.menuButton.activeMenu .navicon:before,
.menuButton.activeMenu .navicon:after {
  top: 0; }
