.languageContainer {
  text-transform: uppercase; }
  .languageContainer a {
    display: inline-block; }
  .languageContainer > a {
    margin: 0 5px; }
  .languageContainer .font_300 {
    font-weight: 300; }
  .languageContainer .currentLangMobile {
    font-weight: 600; }
    .languageContainer .currentLangMobile:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 2px;
      left: 0;
      background-color: var(--secondary-background); }
  .languageContainer .currentLang {
    padding-left: 20px;
    padding-right: 20px;
    position: relative; }
    .languageContainer .currentLang:before {
      height: auto;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute;
      font-family: '';
      font-family: 'booking-engine' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 7px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e901"; }
