.selectMaskOut {
  position: relative;
  width: 100%; }
  .selectMaskOut .selectMask {
    position: relative;
    border-radius: 5px;
    margin-top: 6px;
    border: 1px solid;
    height: 40px;
    cursor: pointer; }
    .selectMaskOut .selectMask .text {
      width: 100%;
      height: 100%;
      line-height: 40px;
      padding-left: 10px;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-size: 17px;
      font-weight: 300; }
    .selectMaskOut .selectMask .icon {
      width: 40px;
      height: 39px;
      border-left: 1px solid;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      font-size: 24px; }
      .selectMaskOut .selectMask .icon:before {
        top: 50%;
        left: 50%;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: var(--title-color); }

@media (max-width: 600.5px) {
  .selectMaskOut {
    font-size: 11px; }
    .selectMaskOut .selectMask {
      height: 33px; }
      .selectMaskOut .selectMask .text {
        font-size: calc(10px + 0.25vw);
        padding: 5px 33px 5px 7px;
        height: 33px;
        line-height: 22.5px; }
        .selectMaskOut .selectMask .text + i {
          width: 33px;
          font-size: 17px;
          line-height: 33px;
          height: 32px; } }
