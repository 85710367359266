.container {
  position: relative; }
  .container .outer:not(.lang):not(.prezzi):not(.onlyCounter):not(.ospitiDetail) {
    margin-right: 1.5vw;
    width: 17vw;
    max-width: 340px;
    min-width: 200px;
    position: relative; }
  .container .inner .button {
    position: relative; }
  .container .popup {
    position: absolute;
    background: var(--main-background-color);
    width: 280px;
    padding: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 11;
    display: none;
    -webkit-box-shadow: 0px 2px 10px -3px #000;
            box-shadow: 0px 2px 10px -3px #000;
    cursor: pointer; }
    .container .popup.ospitiDetail {
      margin-bottom: 0px !important;
      top: 36px !important;
      margin-top: 0px !important; }
    .container .popup.onlyCounter {
      margin-bottom: 20px !important; }
      .container .popup.onlyCounter .text {
        padding-left: 15px; }
    .container .popup.lang {
      top: 6px !important;
      width: 100px;
      text-align: center; }
      .container .popup.lang a {
        color: var(--title-color); }
    .container .popup.prezzi {
      margin-bottom: calc(3px + 0.5vw) !important; }
      .container .popup.prezzi > div:not(:last-child) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        position: relative; }
        .container .popup.prezzi > div:not(:last-child):after {
          content: "";
          position: absolute;
          bottom: 0;
          height: 1px;
          background-color: var(--text-color);
          opacity: 0.6;
          left: 0;
          right: 0; }
      .container .popup.prezzi h4 {
        font-size: calc(11px + 0.25vw);
        line-height: 20px;
        margin-bottom: 4px; }
      .container .popup.prezzi p {
        display: block;
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 12px; }
        .container .popup.prezzi p > span {
          display: inline-block; }
          .container .popup.prezzi p > span:nth-child(odd) {
            width: calc(55% - 3px);
            text-align: left;
            vertical-align: top; }
          .container .popup.prezzi p > span:nth-child(even) {
            width: calc(45% - 3px);
            text-align: right;
            vertical-align: bottom;
            color: var(--title-color);
            font-weight: 500; }
    .container .popup.open {
      display: block; }
    .container .popup:after {
      content: " ";
      width: 0;
      height: 0;
      position: absolute;
      pointer-events: none;
      border: 30px solid transparent;
      border-color: rgba(136, 183, 213, 0); }
    .container .popup:before {
      content: " ";
      width: 0;
      height: 0;
      position: absolute;
      pointer-events: none;
      border: 10px solid transparent;
      border-color: rgba(136, 183, 213, 0);
      filter: blur(8px);
      -webkit-filter: blur(2px); }
    .container .popup.top {
      top: 47px;
      margin-top: 40px; }
      .container .popup.top:after {
        left: 50%;
        top: -40px;
        margin-left: -30px;
        border-bottom-color: var(--main-background-color); }
      .container .popup.top:before {
        top: -19px;
        left: 50%;
        margin-left: -10px;
        border-bottom-color: #000; }
      @media (max-width: 900.5px) {
        .container .popup.top:not(.ospitiDetail) {
          margin-top: 36px;
          -webkit-transform: initial;
              -ms-transform: initial;
                  transform: initial;
          left: 0; } }
      @media (max-width: 600.5px) {
        .container .popup.top {
          margin-top: 26px; } }
    .container .popup.right {
      right: 45px;
      margin-right: 40px; }
      .container .popup.right:after {
        top: 50%;
        right: -45px;
        margin-top: -30px;
        border-left-color: var(--main-background-color); }
      .container .popup.right:before {
        right: -29px;
        top: 50%;
        margin-top: -14px;
        border-left-color: #000; }
    .container .popup.bottom {
      bottom: 40px;
      margin-bottom: 40px; }
      .container .popup.bottom:after {
        bottom: -40px;
        left: 50%;
        margin-left: -30px;
        border-top-color: var(--main-background-color); }
      .container .popup.bottom:before {
        bottom: -20px;
        left: 50%;
        margin-left: -10px;
        border-top-color: #000; }
      @media (max-width: 900.5px) {
        .container .popup.bottom {
          margin-bottom: 26px;
          left: 70%; } }
    .container .popup.left {
      left: 45px;
      margin-left: 40px; }
      .container .popup.left:after {
        top: 50%;
        left: -45px;
        margin-top: -30px;
        border-right-color: var(--main-background-color); }
      .container .popup.left:before {
        left: -29px;
        top: 50%;
        margin-top: -14px;
        border-right-color: #000; }
    @media (max-width: 900.5px) {
      .container .popup:not(.ospitiDetail).top:before, .container .popup:not(.ospitiDetail).bottom:before, .container .popup:not(.ospitiDetail).top:after, .container .popup:not(.ospitiDetail).bottom:after {
        left: 32%; } }
    @media (max-width: 600.5px) {
      .container .popup:not(.ospitiDetail).bottom {
        margin-bottom: 18px; } }
    @media (max-width: 430.5px) {
      .container .popup:not(.ospitiDetail).bottom {
        -webkit-transform: initial;
            -ms-transform: initial;
                transform: initial;
        left: 0; }
      .container .popup:not(.ospitiDetail).bottom.prezzi {
        left: initial;
        right: 0;
        -webkit-transform: initial;
            -ms-transform: initial;
                transform: initial; }
      .container .popup:not(.ospitiDetail).top:before, .container .popup:not(.ospitiDetail).top:after {
        left: 28%; } }

@media (max-width: 900.5px) {
  .container .outer:not(.lang):not(.prezzi):not(.ospitiDetail) {
    width: 40vw; } }

@media (max-width: 600.5px) {
  .container .outer:not(.lang):not(.prezzi):not(.ospitiDetail) {
    min-width: 45vw; } }

@media (max-width: 430.5px) {
  .container .outer:not(.lang):not(.prezzi):not(.onlyCounter):not(.ospitiDetail) {
    min-width: 150px; } }
