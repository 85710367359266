.card-v {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 33%;
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 33%; }
  .card-v .gallery {
    width: 100%; }
  .card-v .descrizione {
    width: 100%;
    padding: 20px 0; }
    .card-v .descrizione .info {
      border-left: 1px solid var(--main-background-color);
      margin-left: -1px;
      padding-left: 21px; }

@media (min-width: 1023.5px) {
  .card-v:nth-child(3n+1) {
    padding-right: 2%; }
  .card-v:nth-child(3n+2) {
    padding-right: 1%;
    padding-left: 1%; }
  .card-v:nth-child(3n+3) {
    padding-left: 2%; } }

@media (min-width: 650.5px) and (max-width: 1023.5px) {
  .card-v {
    padding-left: initial;
    padding-right: initial;
    width: 50%;
    max-width: 50%; }
    .card-v:nth-child(2n+1) {
      padding-right: 2%; }
    .card-v:nth-child(2n+2) {
      padding-left: 2%; } }

@media (max-width: 650.5px) {
  .card-v {
    padding-left: initial;
    padding-right: initial;
    width: 100%;
    max-width: initial; } }

@media (min-width: 1023.5px) {
  :global(.has-map) .card-v {
    padding-left: initial;
    padding-right: initial;
    width: 50%;
    max-width: 50%; }
    :global(.has-map) .card-v:nth-child(2n+1) {
      padding-right: 2%; }
    :global(.has-map) .card-v:nth-child(2n+2) {
      padding-left: 2%; } }

@media (max-width: 1023.5px) {
  :global(.has-map) .card-v {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 100%; }
    :global(.has-map) .card-v:nth-child(2n+1) {
      padding-right: 0; }
    :global(.has-map) .card-v:nth-child(2n+2) {
      padding-left: 0; } }

.card-h {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%; }
  .card-h .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%; }
  .card-h .gallery {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 50%; }
  .card-h .descrizione {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 50%; }

.noimgcontain {
  overflow: hidden;
  position: relative;
  min-height: 250px; }
  .noimgcontain:before {
    content: "";
    display: block;
    padding-bottom: 66%; }

.gallery {
  position: relative; }
  .gallery .prezzo {
    border-top-left-radius: 40px;
    position: absolute;
    width: 175px;
    height: 75px;
    z-index: 1;
    bottom: 0;
    right: 0;
    font-size: calc(18px + 0.4vw);
    text-align: right;
    padding-right: 10px;
    white-space: nowrap;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: var(--secondary-color);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .gallery .prezzo .prezzo_intero_da {
      padding-left: 15px;
      display: block;
      clear: both; }
      .gallery .prezzo .prezzo_intero_da .apartire {
        font-size: calc(12px + 0.25vw); }
    .gallery .prezzo .prezzoinner {
      display: inline-block;
      width: 88%;
      margin-right: 0;
      margin-left: auto; }
    .gallery .prezzo .offerta {
      opacity: 0.6;
      float: right;
      font-size: calc(13px);
      display: inline-block;
      margin-left: 10px; }
    .gallery .prezzo .prezzo_base {
      float: left;
      font-size: calc(13px);
      display: inline-block;
      position: relative; }
    .gallery .prezzo .prezzo_intero {
      clear: both;
      float: none;
      display: block;
      position: relative; }

.descrizione {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .descrizione h3 {
    margin-bottom: 10px;
    font-size: calc(14px + 0.3vw);
    font-weight: 500; }
  .descrizione p {
    font-size: calc(10px + 0.2vw);
    margin-bottom: 15px; }
  .descrizione .testo {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 50%;
    padding: 0 20px;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .descrizione .info {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 35%;
    margin: 0;
    padding: 0 20px;
    min-width: 180px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .descrizione .info .btn_container {
      margin-top: 15px; }
  .descrizione .scopri {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }

.round-btn {
  font-size: 13px; }
