.error {
  min-height: 60vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px; }
  .error h1 {
    margin-bottom: 50px; }
