.map {
  height: 100%; }
  .map :global .leaflet-container {
    height: 100%; }
  .map :global .leaflet-popup-content {
    margin: 0; }

.map h3 {
  margin-bottom: 10px;
  font-size: calc(14px + 0.3vw);
  font-weight: 500; }
