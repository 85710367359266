.paginationContainer {
  display: inline-block;
  white-space: nowrap;
  text-align: right;
  float: right;
  margin-left: auto; }

.pagination {
  margin-top: 15px; }
  .pagination li {
    display: inline-block;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    position: relative; }
    .pagination li:before {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--text-color);
      opacity: 0.5; }
    .pagination li.prev_item:before {
      content: none; }
    .pagination li .page_link {
      width: 29px;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      padding: 8px 0;
      color: var(--text-color);
      margin: 0 3px;
      -webkit-transition: color 0.2s ease-in-out;
      -o-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out; }
    .pagination li:hover, .pagination li.active {
      background-color: var(--title-color); }
      .pagination li:hover .page_link, .pagination li.active .page_link {
        color: white; }
