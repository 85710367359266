.listContainer {
  margin-top: 30px;
  display: inline-block;
  width: 100%; }
  .listContainer .paginationInfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 15px;
    row-gap: 10px; }
  .listContainer .paginationTextualInfo {
    display: inline-block;
    margin-top: 25px; }
  .listContainer .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style-type: none;
    width: 100%;
    padding-bottom: 50px; }

.empty {
  min-height: 60vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .empty h1 {
    margin-bottom: 50px; }

.listHeader {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.ordineTipo,
.ordineDirezione {
  display: inline-block;
  border: 1px solid var(--title-color);
  border-radius: 4px;
  margin-right: 10px;
  white-space: nowrap; }
  .ordineTipo span,
  .ordineDirezione span {
    border-right: 1px solid var(--title-color);
    display: inline-block;
    padding: 9px 15px;
    font-size: 12px;
    cursor: pointer; }
    .ordineTipo span:last-child,
    .ordineDirezione span:last-child {
      border-right: none; }
    .ordineTipo span.active, .ordineTipo span:hover,
    .ordineDirezione span.active,
    .ordineDirezione span:hover {
      background-color: var(--title-color);
      color: white; }

@media (max-width: 600.5px) {
  .listContainer {
    margin-top: 0px; } }
