.swiper-container {
  position: relative;
  height: 100%; }
  .swiper-container .w_auto {
    width: auto;
    min-width: 10px; }
  .swiper-container .bg_image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-filter: blur(7px);
            filter: blur(7px);
    opacity: 0.6;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .swiper-container .imageSlide {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 250px; }
    .swiper-container .imageSlide:before {
      content: "";
      display: block;
      padding-bottom: 66%; }
    .swiper-container .imageSlide img {
      margin: 0 auto;
      display: block; }
  .swiper-container .title-color > span {
    border-color: var(--title-color);
    background-color: var(--title-color); }

.spacer {
  position: relative;
  display: block;
  background: var(--tertiary-background); }
  .spacer:before {
    content: "";
    display: block;
    padding-bottom: 66%;
    -webkit-box-shadow: inset 0px 0px 20px -8px #000000;
            box-shadow: inset 0px 0px 20px -8px #000000; }

article .spacerbefore {
  position: relative; }
  article .spacerbefore:before {
    content: "";
    position: absolute;
    background: var(--tertiary-background);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-box-shadow: inset 0px 0px 20px -8px #000000;
            box-shadow: inset 0px 0px 20px -8px #000000; }
