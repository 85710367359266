.counter {
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .counter .labelContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .counter .count {
    font-size: 30px;
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px; }
  .counter .counters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .counter .button {
    border: 2px solid;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-left: 0.5px; }
  .counter .small {
    font-size: 11px;
    display: block;
    margin-bottom: -5px; }
  .counter .disable {
    opacity: 0.4;
    pointer-events: none; }
