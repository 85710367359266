.banner {
  width: 100%;
  height: 40vh;
  max-height: 50vw;
  min-height: 280px;
  position: relative; }
  .banner:before {
    content: "";
    position: absolute;
    bottom: 40px;
    left: -30px;
    right: -30px;
    top: 0;
    background: var(--tertiary-background);
    -webkit-box-shadow: inset 0 -6px 30px -12px #000000;
            box-shadow: inset 0 -6px 30px -12px #000000; }
  .banner > div {
    padding-bottom: 40px; }

.location {
  margin-top: 20px;
  font-size: 20px; }

h1 {
  display: inline-block; }

.testolibero {
  margin-top: 25px;
  margin-bottom: 25px; }

.testolibero p {
  padding-top: 10px;
  padding-bottom: 10px; }

.titolo h2 {
  text-align: center;
  font-size: calc(18px + .25vw);
  margin-bottom: 25px; }

.titolo h3 {
  text-align: center; }

.luogo {
  float: right;
  padding-top: 6px; }

.dinamicMessage {
  margin-top: 10px; }
  @media (min-width: 600.5px) {
    .dinamicMessage {
      padding-right: 15px; } }

.mainCol {
  margin-top: 50px; }

.mainCol img {
  max-width: 100%; }

.banner + .mainCol {
  margin-top: 0; }

.warningMessageInner {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center; }
  .warningMessageInner .link {
    text-decoration: underline;
    cursor: pointer; }
  .warningMessageInner .icon {
    margin-left: 10px;
    cursor: pointer; }
    .warningMessageInner .icon:before {
      color: var(--secondary-color);
      font-size: 18px;
      font-weight: bold; }

.calendarioContainer,
.mappaContainer,
.orariContainer,
.serviziContainer,
.pagamentoContainer,
.descrizioneContainer,
.caratteristicheContainer,
.formContainer {
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: calc(15px + 2.5vw) 0;
  border-bottom: 1px solid var(--searchbar-background); }
  .calendarioContainer .titolo,
  .mappaContainer .titolo,
  .orariContainer .titolo,
  .serviziContainer .titolo,
  .pagamentoContainer .titolo,
  .descrizioneContainer .titolo,
  .caratteristicheContainer .titolo,
  .formContainer .titolo {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 33%; }
  .calendarioContainer .text,
  .mappaContainer .text,
  .orariContainer .text,
  .serviziContainer .text,
  .pagamentoContainer .text,
  .descrizioneContainer .text,
  .caratteristicheContainer .text,
  .formContainer .text {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 67%; }

.orariContainer .text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.orariContainer .singleOrario {
  width: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: calc(12px + 0.25vw); }
  .orariContainer .singleOrario h3 {
    padding-right: calc(10px + 0.35vw); }
  .orariContainer .singleOrario p > span {
    vertical-align: middle;
    white-space: nowrap;
    display: inline-block;
    padding-right: calc(5px + 0.2vw); }

.chisiamoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.caratteristicheContainer .servizio {
  width: 33%; }

.serviziContainer .text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.serviziContainer .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%; }

.serviziContainer .servizio {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 50%; }

.pagamentoContainer .penali {
  font-size: 12px;
  margin-top: 10px;
  font-weight: lighter;
  color: var(--text-color);
  padding-right: 20px;
  min-height: 120px; }
  .pagamentoContainer .penali .cancellazione {
    margin-top: 20px;
    display: inline-block;
    width: 100%; }

.pagamentoContainer .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px; }
  .pagamentoContainer .text .metodo {
    margin-right: 2%;
    margin-left: 2%;
    width: 100%; }

.pagamentoContainer .servizi_pagamento,
.pagamentoContainer .formPrenota {
  display: block;
  width: 67%; }

.update_container {
  width: 100%;
  padding: 15px;
  text-align: center;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s, max-height 0.3s;
  -o-transition: opacity 0.3s, max-height 0.3s;
  transition: opacity 0.3s, max-height 0.3s; }
  .update_container .button_update {
    background: var(--secondary-background);
    display: inline-block;
    clear: both;
    padding: 10px 20px;
    margin-top: 20px;
    color: var(--secondary-color);
    border-radius: 30px;
    cursor: pointer;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s; }
    .update_container .button_update:hover {
      -webkit-box-shadow: 0px 0px 25px -4px var(--title-color);
              box-shadow: 0px 0px 25px -4px var(--title-color); }
  .update_container:not(.shown) {
    overflow: hidden; }
  .update_container.shown {
    max-height: 150px;
    opacity: 1;
    pointer-events: auto; }

.mappaContainer {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%; }
  .mappaContainer .mappa {
    width: 67%;
    position: relative;
    display: block;
    height: 500px;
    z-index: 0; }

.mainCol {
  max-width: 1280px; }
  .mainCol > div:last-child {
    border-bottom: none;
    border-bottom: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    margin-bottom: 100px; }

.cta_scegli_metodo {
  font-weight: 400;
  font-size: 15px;
  padding-top: 15px;
  max-width: 200px;
  margin: 0 auto;
  text-decoration: underline;
  color: var(--secondary-background); }

.richiestaInfoText h4 {
  padding-top: 20px;
  cursor: pointer;
  text-decoration: underline; }

.richiestaInfoText form {
  -webkit-transition: max-height 1s ease-in-out, padding 1s ease-in-out;
  -o-transition: max-height 1s ease-in-out, padding 1s ease-in-out;
  transition: max-height 1s ease-in-out, padding 1s ease-in-out;
  max-height: 1000px;
  overflow: hidden;
  padding-left: 6px; }

.richiestaInfoText.max_h_0 form {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0; }

@media (max-width: 1023.5px) {
  .mainCol {
    width: 80%; }
  .calendarioContainer,
  .pagamentoContainer {
    clear: both; }
    .calendarioContainer .titolo,
    .pagamentoContainer .titolo {
      width: 100%;
      margin-bottom: 20px; }
    .calendarioContainer .text,
    .pagamentoContainer .text {
      width: 100%; }
    .calendarioContainer .penali,
    .pagamentoContainer .penali {
      min-height: 0;
      padding: 0; }
    .calendarioContainer .servizi_pagamento,
    .calendarioContainer .formPrenota,
    .pagamentoContainer .servizi_pagamento,
    .pagamentoContainer .formPrenota {
      display: block;
      width: 99%;
      margin: 0 0 20px 0.5%; } }

@media (max-width: 900.5px) {
  .mainCol > div:last-child {
    margin-bottom: 170px; } }

@media (max-width: 800.5px) {
  .mainCol {
    width: 90%; } }

@media (max-width: 700.5px) {
  .serviziContainer,
  .orariContainer,
  .descrizioneContainer,
  .caratteristicheContainer,
  .formContainer,
  .mappaContainer {
    clear: both; }
    .serviziContainer .titolo,
    .orariContainer .titolo,
    .descrizioneContainer .titolo,
    .caratteristicheContainer .titolo,
    .formContainer .titolo,
    .mappaContainer .titolo {
      width: 100%;
      margin-bottom: 20px; }
    .serviziContainer .text,
    .orariContainer .text,
    .descrizioneContainer .text,
    .caratteristicheContainer .text,
    .formContainer .text,
    .mappaContainer .text {
      width: 100%; }
  .mappaContainer .mappa {
    width: 100%;
    height: 350px; }
  .caratteristicheContainer .servizio {
    width: 50%; }
  .mainCol {
    width: 90%; } }

@media (max-width: 500.5px) {
  .orariContainer .singleOrario {
    width: 100%;
    display: inline-block; }
  .pagamentoContainer .penali {
    min-height: 0;
    padding: 0; }
  .pagamentoContainer .text {
    display: block; }
    .pagamentoContainer .text .metodo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 2% 0;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .pagamentoContainer .text .metodo > * {
        margin: 0; } }

:global .leaflet-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  outline: none; }

@media (max-width: 700.5px) {
  :global .leaflet-container {
    height: 350px; } }
