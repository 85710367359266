.serviceGroup {
  width: 100%;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .serviceGroup .prezzo {
    font-size: calc(18px + 0.25vw);
    font-weight: 300;
    min-width: 90px;
    text-align: right; }
  .serviceGroup .prezzo,
  .serviceGroup .input_group {
    padding-top: 20px; }
    .serviceGroup .prezzo label,
    .serviceGroup .input_group label {
      margin: 0 30px 0 10px;
      font-size: calc(12px + 0.25vw); }
  .serviceGroup .smallCounterContainer {
    width: 110px;
    display: inline-block;
    margin: 0 20px 0 0; }
  .serviceGroup .serviceInfos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

@media (min-width: 1200.5px) {
  .serviceGroup .smallCounterContainer {
    margin: 0 30px 0 0; } }

@media (max-width: 600.5px) {
  .serviceGroup .smallCounterContainer {
    margin: 0 10px; } }

@media (max-width: 500.5px) {
  .serviceGroup.hasCounters {
    padding-bottom: 0;
    -webkit-transition: padding-bottom 0.3s 0.15s;
    -o-transition: padding-bottom 0.3s 0.15s;
    transition: padding-bottom 0.3s 0.15s;
    margin-bottom: 10px;
    overflow: hidden; }
    .serviceGroup.hasCounters .smallCounterContainer {
      position: absolute;
      pointer-events: none;
      bottom: 0;
      -webkit-transition: opacity 0.3s 0s;
      -o-transition: opacity 0.3s 0s;
      transition: opacity 0.3s 0s;
      opacity: 0; }
    .serviceGroup.hasCounters .smallCounterContainer.first_el {
      left: 0; }
    .serviceGroup.hasCounters .smallCounterContainer.second_el {
      right: 0; }
    .serviceGroup.hasCounters.hasCountersVisibles {
      padding-bottom: 55px;
      opacity: 1;
      -webkit-transition: padding-bottom 0.3s;
      -o-transition: padding-bottom 0.3s;
      transition: padding-bottom 0.3s; }
      .serviceGroup.hasCounters.hasCountersVisibles .smallCounterContainer {
        pointer-events: auto;
        -webkit-transition: opacity 0.3s 0.15s;
        -o-transition: opacity 0.3s 0.15s;
        transition: opacity 0.3s 0.15s;
        opacity: 1; } }
