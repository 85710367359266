@charset "UTF-8";
.warningMessage {
  margin-top: -12px;
  margin-bottom: 12px; }

.warningMessageInner {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center; }
  .warningMessageInner .link {
    text-decoration: underline;
    cursor: pointer; }
  .warningMessageInner .icon {
    margin-left: 10px;
    cursor: pointer; }
    .warningMessageInner .icon:before {
      color: var(--secondary-color);
      font-size: 18px;
      font-weight: bold; }

@media (max-width: 700px) {
  .hideMobile {
    display: none; } }

.searchBar {
  z-index: 1100;
  position: absolute;
  left: 0;
  right: 0;
  padding: 12px 0;
  display: block;
  /* INIZIO informazioni da rimuovere quando verrà attivato il "prezzo /notte" */
  /* FINE informazioni da rimuovere quando verrà attivato il "prezzo /notte" */ }
  .searchBar.relative {
    position: relative; }
  .searchBar + div {
    -webkit-transition: padding-top 0.4s;
    -o-transition: padding-top 0.4s;
    transition: padding-top 0.4s; }
  .searchBar.padding_60 + div {
    padding-top: 60px; }
  .searchBar.padding_100 + div {
    padding-top: 100px; }
  .searchBar.padding_130 + div {
    padding-top: 145px; }
  .searchBar .w_66 {
    width: 65.6665%; }
  .searchBar .w_33 {
    width: 34.3332%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .searchBar .w_33 button {
      margin-right: 0;
      margin-left: auto; }
  .searchBar .w_75 {
    width: 75%; }
  .searchBar .w_25 {
    width: 25%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .searchBar .w_25 button {
      margin-right: 0;
      margin-left: auto; }
  .searchBar .left_block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .searchBar .left_block > * {
      width: 50%; }
  .searchBar .dettagli {
    position: relative;
    padding-right: 5%;
    border-right: 1px solid var(--secondary-color);
    font-size: calc(10px + 0.25vw); }
    .searchBar .dettagli .bottom_riepilogoFlex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .searchBar .dettagli .bottom_riepilogoFlex h4 {
        font-size: calc(12px + 0.35vw);
        line-height: calc(14px + 0.25vw);
        padding-right: 20px;
        color: var(--secondary-color);
        font-weight: 400; }
    .searchBar .dettagli .CTAmodifica {
      padding-top: 5px;
      position: absolute;
      bottom: 0;
      right: 0;
      text-decoration: underline;
      cursor: pointer; }
    .searchBar .dettagli .inline_block {
      display: inline-block; }
  .searchBar .smalltext {
    font-size: calc(9px + 0.25vw);
    display: block; }
  .searchBar .prezzi {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-left: 5%; }
    .searchBar .prezzi .prezzoContainer {
      color: var(--text-color);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding-top: 3px; }
    .searchBar .prezzi .prezziCTA {
      width: 110px;
      padding: 0 10px;
      cursor: pointer; }
      .searchBar .prezzi .prezziCTA .pad_0 {
        padding-top: 0;
        padding-bottom: 0; }
      .searchBar .prezzi .prezziCTA i {
        margin-right: 0;
        font-size: calc(30px + 0.5vw); }
      .searchBar .prezzi .prezziCTA i + div {
        font-size: calc(7px + 0.25vw);
        font-size: calc(9px + 0.25vw); }
      .searchBar .prezzi .prezziCTA:hover i + div {
        text-decoration: underline; }
  .searchBar .title {
    padding-right: 5%;
    border-right: 1px solid var(--secondary-color);
    font-size: calc(12px + 0.35vw); }
  .searchBar .price {
    font-size: calc(22px + 0.5vw); }
  .searchBar .apartire {
    font-size: calc(12px + 0.4vw); }
  .searchBar .nowrap {
    white-space: nowrap; }
  .searchBar .label {
    text-align: center;
    padding-bottom: 5px;
    font-weight: 400; }
  .searchBar .verificaDisponibilita,
  .searchBar .searchInfos,
  .searchBar .inner {
    margin: 0 auto;
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative; }
  .searchBar .verificaDisponibilita {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .searchBar .verificaDisponibilita .w_33 {
    width: 50%; }
  .searchBar .verificaDisponibilita .w_66 {
    width: auto;
    display: inline-block;
    max-width: 45%;
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle; }
  .searchBar .verificaDisponibilita .title {
    display: none; }
  .searchBar .inner {
    padding: 5px 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .searchBar .inner .calendarioContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .searchBar .inner .ospiti {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end; }
  .searchBar .searchFields {
    -webkit-transition: height 0.4s, opacity 0.4s 0.1s, overflow 0s linear 1s;
    -o-transition: height 0.4s, opacity 0.4s 0.1s, overflow 0s linear 1s;
    transition: height 0.4s, opacity 0.4s 0.1s, overflow 0s linear 1s;
    height: 80px;
    overflow: visible; }
  .searchBar:not(.searchOpen) .searchFields {
    -webkit-transition: height 0.4s, opacity 0.4s, overflow 0s;
    -o-transition: height 0.4s, opacity 0.4s, overflow 0s;
    transition: height 0.4s, opacity 0.4s, overflow 0s;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    height: 0; }
  .searchBar .searchInfos {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-height: 90px;
    -webkit-transition: max-height 0.4s, opacity 0.4s;
    -o-transition: max-height 0.4s, opacity 0.4s;
    transition: max-height 0.4s, opacity 0.4s;
    opacity: 1; }
    .searchBar .searchInfos .searchInfoInner {
      width: 100%; }
  .searchBar:not(.riepilogoOpen) .searchInfos {
    pointer-events: none;
    -webkit-transition: max-height 0.4s, opacity 0.4s 0.1s;
    -o-transition: max-height 0.4s, opacity 0.4s 0.1s;
    transition: max-height 0.4s, opacity 0.4s 0.1s;
    opacity: 0;
    max-height: 0; }
  .searchBar .verificaDisponibilita {
    -webkit-transition: max-height 0.4s, opacity 0.4s;
    -o-transition: max-height 0.4s, opacity 0.4s;
    transition: max-height 0.4s, opacity 0.4s;
    opacity: 1;
    max-height: 90px; }
    .searchBar .verificaDisponibilita .verificaCTA {
      padding-left: 7%; }
      .searchBar .verificaDisponibilita .verificaCTA .innerCTA {
        max-width: 300px;
        cursor: pointer; }
        .searchBar .verificaDisponibilita .verificaCTA .innerCTA:hover i + div {
          text-decoration: underline; }
      .searchBar .verificaDisponibilita .verificaCTA i {
        font-size: calc(30px + 0.35vw); }
      .searchBar .verificaDisponibilita .verificaCTA i + div {
        word-spacing: 300px;
        font-size: calc(12px + 0.35vw); }
  .searchBar:not(.initialinfoOpen) .verificaDisponibilita {
    pointer-events: none;
    -webkit-transition: max-height 0.4s, opacity 0.4s 0.1s;
    -o-transition: max-height 0.4s, opacity 0.4s 0.1s;
    transition: max-height 0.4s, opacity 0.4s 0.1s;
    opacity: 0;
    max-height: 0;
    overflow: hidden; }

.nottiContainer {
  padding-left: 20px; }
  .nottiContainer .nottiInput {
    line-height: 39px;
    height: 39px;
    text-align: center;
    border-bottom: 1px solid;
    font-size: 17px; }

@media (min-width: 1023.5px) {
  .searchBar.bottom .verificaDisponibilita,
  .searchBar.bottom .searchInfos,
  .searchBar.bottom .inner {
    width: 80%;
    max-width: 1280px; } }

@media (min-width: 1280.5px) {
  .searchBar.bottom .verificaDisponibilita,
  .searchBar.bottom .searchInfos,
  .searchBar.bottom .inner {
    width: 70%; } }

:global(.has-map) .searchBar {
  z-index: 2000; }
  :global(.has-map) .searchBar.padding_60 + div {
    padding-top: 70px; }
  :global(.has-map) .searchBar.padding_100 + div {
    padding-top: 160px; }
  :global(.has-map) .searchBar.padding_130 + div {
    padding-top: 210px; }
  :global(.has-map) .searchBar .searchFields {
    height: 150px; }
  :global(.has-map) .searchBar .searchInfoInner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    :global(.has-map) .searchBar .searchInfoInner > * {
      padding: 0;
      border: none;
      width: 100%; }
    :global(.has-map) .searchBar .searchInfoInner .dettagli {
      margin-bottom: 10px; }
  :global(.has-map) .searchBar .searchInfos p {
    font-size: calc(11px + 0.25vw); }
  :global(.has-map) .searchBar .searchInfos button,
  :global(.has-map) .searchBar .ospiti button {
    margin-left: 10px; }
  :global(.has-map) .searchBar .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  :global(.has-map) .searchBar .ospiti,
  :global(.has-map) .searchBar .calendarioContainer {
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  :global(.has-map) .searchBar .ospiti {
    margin-top: 5px; }
  :global(.has-map) .searchBar:not(.searchOpen) .searchFields {
    -webkit-transition: height 0.4s, opacity 0.4s, overflow 0s;
    -o-transition: height 0.4s, opacity 0.4s, overflow 0s;
    transition: height 0.4s, opacity 0.4s, overflow 0s;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    height: 0; }
  :global(.has-map) .searchBar :global #calendar_top {
    width: 100%; }
    :global(.has-map) .searchBar :global #calendar_top .title-color {
      max-width: initial; }
  :global(.has-map) .searchBar :global .DateRangePicker:div {
    width: 100%; }
  :global(.has-map) .searchBar :global .DateRangePicker .DateRangePickerInput {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%; }
    :global(.has-map) .searchBar :global .DateRangePicker .DateRangePickerInput .DateInput {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      width: auto;
      max-width: initial; }

@media (max-width: 1080.5px) {
  .searchBar.padding_60 + div {
    padding-top: 70px; }
  .searchBar.padding_100 + div {
    padding-top: 160px; }
  .searchBar.padding_130 + div {
    padding-top: 210px; }
  .searchBar .searchFields {
    height: 150px; }
  .searchBar .searchInfoInner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .searchBar .searchInfoInner > * {
      padding: 0;
      border: none;
      width: 100%; }
    .searchBar .searchInfoInner .dettagli {
      margin-bottom: 10px; }
  .searchBar .searchInfos p {
    font-size: calc(11px + 0.25vw); }
  .searchBar .searchInfos button,
  .searchBar .ospiti button {
    margin-left: 10px; }
  .searchBar .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .searchBar .ospiti,
  .searchBar .calendarioContainer {
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; } }

@media (max-width: 600.5px) {
  .searchBar.padding_60 + div {
    padding-top: 100px; }
  .searchBar .verificaDisponibilita {
    display: block; }
    .searchBar .verificaDisponibilita button {
      margin: 5px auto 0; }
    .searchBar .verificaDisponibilita h3 {
      font-size: calc(12px + 0.25vw); }
    .searchBar .verificaDisponibilita .price {
      font-size: calc(22px + 0.25vw); }
    .searchBar .verificaDisponibilita .innerCTA > div {
      padding: 0; }
    .searchBar .verificaDisponibilita .verificaCTA i {
      font-size: calc(24px + 0.35vw); }
    .searchBar .verificaDisponibilita .verificaCTA i + div {
      font-size: calc(11px + 0.25vw); }
  .searchBar .w_66, .searchBar .w_33 {
    width: 100%; }
  .searchBar .label:not(.titolo) {
    font-size: 11px; }
  .searchBar .ospiti button {
    padding: 8px 20px; }
  .searchBar .searchFields {
    height: 125px; }
  .nottiContainer {
    padding-left: 5%; }
    .nottiContainer .nottiInput {
      line-height: 33px;
      height: 33px;
      font-size: calc(10px + 0.25vw); } }

@media (max-width: 430.5px) {
  .searchInfoInner .dettagli {
    display: none; }
  .searchInfoInner .prezzi {
    display: block; }
    .searchInfoInner .prezzi button {
      width: 100%;
      margin: 10px auto 0; }
    .searchInfoInner .prezzi h3 {
      display: inline-block;
      width: 50%; }
    .searchInfoInner .prezzi .prezziCTA {
      float: right; } }

.riepilogoOpen .buttonOpenFields {
  opacity: 0;
  pointer-events: none; }

:global .searchFieldsButton {
  display: none; }

@media (max-width: 1080.5px) {
  :global .searchFieldsButton:before {
    content: "\e902";
    font-family: 'booking-engine' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    left: 52%;
    top: 60%;
    font-size: 11px; }
  :global .h_0 + .searchFieldsButton:before {
    content: "\e901";
    left: 52%;
    top: 60%;
    font-size: 11px; }
  :global .fixed_bar .searchFieldsButton {
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    border-radius: 7px;
    bottom: -20px; }
  :global .fixed_bar .h_0 {
    -webkit-transition: height 0.4s 0.2s, opacity 0.3s, overflow 0s 0s;
    -o-transition: height 0.4s 0.2s, opacity 0.3s, overflow 0s 0s;
    transition: height 0.4s 0.2s, opacity 0.3s, overflow 0s 0s;
    overflow: hidden;
    opacity: 0;
    height: 0; } }
