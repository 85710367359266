.button {
  padding: 12px 20px;
  text-align: center;
  border-radius: 30px;
  color: var(--primary-color);
  border: none;
  vertical-align: bottom;
  cursor: pointer;
  width: 100%;
  min-width: 125px;
  max-width: 250px;
  display: block;
  -webkit-transition: opacity 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  font-weight: 400; }
  .button:hover {
    -webkit-box-shadow: 0px 0px 25px -4px var(--title-color);
            box-shadow: 0px 0px 25px -4px var(--title-color); }
  .button:active, .button:focus {
    outline: none; }
  .button.auto {
    width: auto;
    font-size: calc(13px + 0.11vw);
    padding: 10px 30px; }
  .button.small {
    width: auto;
    font-size: 12px;
    padding: 5px 10px; }
    .button.small:hover {
      -webkit-box-shadow: 0px 0px 10px -2px var(--title-color);
              box-shadow: 0px 0px 10px -2px var(--title-color); }

button.button {
  font-size: 16px; }

a.button {
  font-size: calc(13px + 0.11vw);
  max-width: 350px; }

.opacity_05 {
  opacity: 0.7;
  pointer-events: none; }
