.method {
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  background-color: var(--tertiary-background);
  -webkit-transition: 400ms all;
  -o-transition: 400ms all;
  transition: 400ms all;
  color: var(--text-color); }
  .method:first-child {
    margin-left: 0 !important; }
  .method:last-child {
    margin-right: 0 !important; }
  .method.active, .method:hover {
    background-color: var(--secondary-background);
    -webkit-transition: 400ms all;
    -o-transition: 400ms all;
    transition: 400ms all;
    color: var(--title-color); }
    .method.active h3, .method:hover h3 {
      color: var(--secondary-color); }
    .method.active .prezzo, .method:hover .prezzo {
      color: var(--secondary-color); }
  .method h3 {
    font-size: calc(15px + 0.35vw);
    margin-top: 10px; }
    .method h3 span {
      font-weight: 400;
      display: block;
      font-size: calc(11px + 0.35vw); }
  .method .light {
    font-size: calc(9px + 0.35vw);
    font-weight: 300;
    margin-top: 5px; }
  .method .prezzo {
    color: var(--secondary-background);
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 30px;
    white-space: nowrap;
    position: relative;
    display: inline-block; }
    .method .prezzo .offerta {
      opacity: 0.6;
      float: right;
      font-size: initial;
      display: inline-block;
      margin-left: 10px; }
    .method .prezzo .prezzo_base {
      float: left;
      display: inline-block;
      position: relative; }
    .method .prezzo .prezzo_intero {
      clear: both;
      float: none;
      display: block;
      position: relative; }
    @media (min-width: 500.5px) {
      .method .prezzo {
        min-width: 75%; } }
  .method .button {
    -webkit-transition: 400ms all;
    -o-transition: 400ms all;
    transition: 400ms all;
    margin: 20px auto 0px auto;
    position: relative;
    border-radius: 50%;
    border: 2px solid;
    height: 20px;
    width: 20px; }
    .method .button .inner {
      -webkit-transition: 400ms all;
      -o-transition: 400ms all;
      transition: 400ms all;
      position: absolute;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      opacity: 0;
      left: 3px;
      top: 3px; }
  .method:hover .button .inner, .method.active .button .inner {
    opacity: 1;
    -webkit-transition: 400ms all;
    -o-transition: 400ms all;
    transition: 400ms all; }
