
.iconButton {
	width: 100%;
	padding: 10px 0;
	transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.iconButton:hover {
	cursor: pointer;
	box-shadow: 0px 0px 25px -4px var(--title-color);
}

.buttonContent {
	width: 90%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}