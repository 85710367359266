.iconText {
  padding-top: 6px;
  padding-bottom: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .iconText .icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 28px; }
  .iconText .text {
    display: inline-block;
    line-height: 110%;
    font-size: calc(10px + 0.25vw);
    padding-right: 10px; }
    .iconText .text.font_300 {
      font-weight: 300; }
    .iconText .text.font_400 {
      font-weight: 400; }
    .iconText .text.font_500 {
      font-weight: 500; }
  h2 .iconText .text {
    font-size: calc(18px + 0.25vw);
    padding-left: 0.35vw;
    font-weight: 400; }
  h2 .iconText .icon {
    font-size: 50px; }
  .iconText.big .icon {
    font-size: 36px; }
  .iconText.big .text {
    font-size: calc(11px + 0.35vw); }
  .iconText.small .icon {
    font-size: 22px; }
  .iconText.small .text {
    font-size: calc(8px + 0.25vw); }
