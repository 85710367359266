@media (min-width: 700px) {
  .home {
    height: calc(100vh - 75px);
    max-height: calc(100vh - 75px);
    min-height: calc(100vh - 75px);
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    overflow: hidden; }
    .home .showMobile {
      display: none; }
    .home .content {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .home .content .list {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        overflow-y: scroll; } }

@media (max-width: 700px) {
  .home .hideMobile {
    display: none; }
  .home .content {
    width: 100% !important; } }

.list .centerColumn {
  width: 90%;
  margin: 0 auto; }

.closeIcon {
  padding: 5px;
  -ms-flex-item-align: end;
      align-self: flex-end; }
  .closeIcon i:before {
    color: #000;
    font-size: 32px;
    font-weight: bold; }

.warningMessageInner {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center; }
  .warningMessageInner .link {
    text-decoration: underline;
    cursor: pointer; }
  .warningMessageInner .icon {
    margin-left: 10px;
    cursor: pointer; }
    .warningMessageInner .icon:before {
      color: var(--secondary-color);
      font-size: 18px;
      font-weight: bold; }
