.bannerContatti {
  padding: calc(50px + 10%) 5%;
  text-align: center; }
  .bannerContatti img {
    z-index: 1; }
  .bannerContatti h1 {
    font-size: calc(30px + 0.5vw); }
  .bannerContatti h2 {
    margin-top: 20px; }
  .bannerContatti h1, .bannerContatti h2 {
    z-index: 3;
    position: relative;
    color: #fff; }
  .bannerContatti:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    mix-blend-mode: multiply;
    z-index: 2; }
