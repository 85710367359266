.confirm .mainCol {
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 1280px; }
  .confirm .mainCol > div:last-child {
    border-bottom: none;
    border-bottom: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%; }

.confirm .imgContainerOuter {
  padding-right: 20px; }

.confirm .imgContainer {
  border-radius: 5px; }

.confirm .imgContainer::before {
  content: "";
  display: block;
  padding-bottom: 65%; }

.confirm .location {
  font-size: 20px;
  width: 100%;
  text-align: center; }

.confirm .containerTitolo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px; }

.confirm h1 {
  display: block; }

.confirm .luogo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-top: 8px;
  text-align: left; }

.confirm .fascia_container {
  clear: both;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  padding: calc(15px + 2.5vw) 0;
  border-bottom: 1px solid var(--searchbar-background); }
  .confirm .fascia_container .titolo {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 33%; }
  .confirm .fascia_container .text {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 67%; }

.confirm .orariContainer .text {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.confirm .orariContainer .singleOrario {
  width: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: calc(12px + 0.25vw); }
  .confirm .orariContainer .singleOrario h3 {
    padding-right: calc(10px + 0.35vw); }
  .confirm .orariContainer .singleOrario p > span {
    vertical-align: middle;
    white-space: nowrap;
    display: inline-block;
    padding-right: calc(5px + 0.2vw); }

.confirm .caratteristicheContainer .servizio {
  width: 33%; }

.confirm .singleEl {
  width: 50%;
  display: inline-block;
  margin-top: 20px;
  word-break: break-word; }

.confirm .dettaglioPrezzi h4, .confirm .dettaglioPrezzi h3 {
  margin-bottom: 10px; }

.confirm .dettaglioPrezzi .text {
  margin-top: 15px; }
  .confirm .dettaglioPrezzi .text .prezzo:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative; }
    .confirm .dettaglioPrezzi .text .prezzo:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: var(--text-color);
      opacity: 0.3;
      left: 0;
      right: 0; }
  .confirm .dettaglioPrezzi .text .prezzo p {
    display: block;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px; }
    .confirm .dettaglioPrezzi .text .prezzo p b {
      font-size: 14px; }
    .confirm .dettaglioPrezzi .text .prezzo p > span {
      display: inline-block; }
      .confirm .dettaglioPrezzi .text .prezzo p > span:nth-child(odd) {
        width: calc(55% - 3px);
        text-align: left;
        vertical-align: top; }
      .confirm .dettaglioPrezzi .text .prezzo p > span:nth-child(even) {
        width: calc(45% - 3px);
        text-align: right;
        vertical-align: bottom;
        color: var(--title-color);
        font-weight: 500;
        font-size: 14px; }
  .confirm .dettaglioPrezzi .text .prezzo.singleLine h3, .confirm .dettaglioPrezzi .text .prezzo.singleLine h4 {
    display: inline-block;
    width: calc(55% - 1px);
    margin: 0;
    padding-top: 4px;
    padding-bottom: 4px; }
  .confirm .dettaglioPrezzi .text .prezzo.singleLine p {
    display: inline-block;
    width: calc(45% - 1px);
    text-align: right;
    vertical-align: top;
    color: var(--title-color);
    font-weight: 500;
    font-size: 14px; }

.confirm .dettaglioPrezzi .cancellazioni {
  padding-top: 40px; }
  .confirm .dettaglioPrezzi .cancellazioni p {
    padding-top: 5px;
    font-size: 11px;
    font-weight: 300; }

.confirm .italic {
  font-style: italic;
  margin-bottom: 15px; }

.confirm .warningMessageInner {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center; }
  .confirm .warningMessageInner .link {
    text-decoration: underline;
    cursor: pointer; }
  .confirm .warningMessageInner .icon {
    margin-left: 10px;
    cursor: pointer; }
    .confirm .warningMessageInner .icon:before {
      color: var(--secondary-color);
      font-size: 18px;
      font-weight: bold; }

@media (max-width: 1023.5px) {
  .confirm .mainCol {
    width: 80%; } }

@media (max-width: 800.5px) {
  .confirm .mainCol {
    width: 90%; } }

@media (max-width: 700.5px) {
  .confirm .fascia_container {
    clear: both; }
    .confirm .fascia_container .titolo {
      width: 100%;
      margin-bottom: 20px;
      padding-right: 0; }
    .confirm .fascia_container .text {
      width: 100%; }
  .confirm .caratteristicheContainer .servizio {
    width: 50%; }
  .confirm .dettaglioPrezzi .cancellazioni {
    padding-right: 20px; }
  .confirm .mainCol {
    width: 90%; } }

@media (max-width: 500.5px) {
  .confirm .orariContainer .singleOrario {
    width: 100%;
    display: inline-block; } }
